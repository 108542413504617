



































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokItemLink, BlokMethodIntro } from '../../../types';
import MediaHandler from '@/components/utilities/media/MediaHandler';
import LinkHandler from '@/components/utilities/links/LinkHandler';
import ButtonWrapper from '@/components/utilities/buttons/ButtonWrapper';

@Component({
  components: { MediaHandler, LinkHandler, ButtonWrapper }
})
export default class MethodIntro extends Vue {
  @Prop() blok!: BlokMethodIntro;
  get link (): BlokItemLink | false {
    if (!this.blok.link.length) { return false; }
    return this.blok.link[0];
  }
  // -> GSAP animation
  $refs: { container: HTMLElement, left: HTMLElement, right: HTMLElement };
  animate (): void {
    const timeline = this.$gsap.timeline({
      defaults: { duration: 1.4, ease: 'power3.out' },
      scrollTrigger: {
        trigger: () => this.$refs.container,
        start: 'top bottom-=100px'
      }
    });
    timeline.to(this.$refs.left, { x: 0, opacity: 1 });
    timeline.to(this.$refs.right, { x: 0, opacity: 1 }, '<');
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.left, { x: -140, opacity: 0 });
    this.$gsap.set(this.$refs.right, { x: 140, opacity: 0 });
    this.animate();
  }
}
